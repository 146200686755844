import { Section } from "../enums/ProjectSections";

export interface IProjectSectionConfig {
  title: string;
  description: string;
}

export default Object.freeze<Record<string, IProjectSectionConfig>>({
  [Section.DESCRIPTION]: {
    title: "project.sections.description.title",
    description: "",
  },
  [Section.PROJECT_DETAILS]: {
    title: "project.sections.project_details.title",
    description: "project.sections.project_details.description",
  },
  [Section.VIRTUAL_TOUR]: {
    title: "project.sections.virtual_tour.title",
    description: "project.sections.virtual_tour.description",
  },
  [Section.SITE_PLAN]: {
    title: "project.sections.site_plan.title",
    description: "project.sections.site_plan.description",
  },
  [Section.LOCATION_MAP]: {
    title: "project.sections.location_map.title",
    description: "project.sections.location_map.description",
  },
  [Section.GALLERY]: {
    title: "project.sections.gallery.title",
    description: "project.sections.gallery.description",
  },
  [Section.UNIT_MIX]: {
    title: "project.sections.unit_mix.title",
    description: "project.sections.unit_mix.description",
  },
  [Section.AVAILABLE_UNITS]: {
    title: "project.sections.available_units.title",
    description: "project.sections.available_units.description",
  },
  [Section.FLOOR_PLANS]: {
    title: "project.sections.floor_plans.title",
    description: "project.sections.floor_plans.description",
  },
  [Section.SALES_TRANSACTION]: {
    title: "project.sections.sales_transaction.title",
    description: "project.sections.sales_transaction.total_description",
  },
  [Section.SCROLL_TO_ENQUIRY]: {
    title: "project.sections.scroll_to_enquiry.title",
    description: "project.sections.scroll_to_enquiry.description",
  },
});
