export enum Section {
  DESCRIPTION = "description",
  PROJECT_DETAILS = "project-details",
  VIRTUAL_TOUR = "virtual-tour",
  SITE_PLAN = "site-plan",
  LOCATION_MAP = "location-map",
  GALLERY = "gallery",
  UNIT_MIX = "unit-mix",
  AVAILABLE_UNITS = "available-units",
  FLOOR_PLANS = "floor-plans",
  SALES_TRANSACTION = "sales-transaction",
  SCROLL_TO_ENQUIRY = "scroll-to-enquiry",
  CURATED_PROPERTIES = "curated-properties",
  CONTACT_SALES = "contact-sales",
}

export enum SectionIndex {
  DESCRIPTION = 0,
  PROJECT_DETAILS = 1,
  VIRTUAL_TOUR = 2,
  SITE_PLAN = 3,
  LOCATION_MAP = 4,
  GALLERY = 5,
  UNIT_MIX = 6,
  AVAILABLE_UNITS = 7,
  FLOOR_PLANS = 8,
  SALES_TRANSACTION = 9,
  SCROLL_TO_ENQUIRY = 10,
  CURATED_PROPERTIES = 11,
  CONTACT_SALES = 12,
}
